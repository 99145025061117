import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import Classnames from "classnames";
import styles from "./ViewFantasyTeamModal.module.scss";

import SprtsToken from "../../../assets/images/icons/24_sprts_token.webp";
import OmniPoints from "../../../assets/images/icons/24_xp.webp";
import {getFantasyTeamByUsernameAndTournamentId} from "../../../services/FantasyService";
import {getConf} from "../../../services/ConfigService";
import {WALLET} from "../../../utilities/ConfigConstants";
import {t} from "i18next";
import {
   getFantasyPointsStatusClass,
   formatNumberToKWithoutRounding,
   getShowingFantasyPoints,
   getSprtsValueFromPoints,
   getBoosterRemainingCircle,
   toolTipProvider,
   getBoosterRemainingTime
} from "../../../helpers/CommonFunctions";
import MoreInfoIcon from "../../../assets/images/icons/more_info_icon.svg";
import classNames from "classnames";
import Styles from "../../Tournament/FantasyTeamSection/TournamentFantasySection.module.scss";
import {ReactComponent as FlashIcon} from "../../../assets/images/icons/flash.svg";
import classnames from "classnames";
import * as StatisticsService from "../../../services/StatisticsService";
import {toast} from "react-toastify";
import {BOOSTER_EXTRA_TIME} from "../../../utilities/Constants";
import * as TournamentService from "../../../services/TournamentService";

const ViewFantasyTeamModal = ({
                                 tournamentId,
                                 refreshFantasy,
                                 isDisplayHidden,
                                 handleModalClose,
                                 onInfoClick,
                                 onBoosterClick,
                                 Token
                              }) => {
   const userName = useSelector((state) => state.auth?.userName);
   const accessToken = useSelector((state) => state.auth?.accessToken);
   const XP_TO_SPRTS_RATIO = getConf(WALLET.XP_TO_SPRTS_RATIO)
   const lang = localStorage.getItem("language") || "en";
   const FANTASY_WARNING_TOAST_ID = "FANTASY_WARNING_TOAST_ID";


   const [isFetchingFantasyTeam, setIsFetchingFantasyTeam] = useState(false);
   const [fantasyTeamObj, setFantasyTeamObj] = useState({});
   const [teamCaptain, setTeamCaptain] = useState(null);
   const [teamViceCaptain, setTeamViceCaptain] = useState(null);
   const [totalPoints, setTotalPoints] = useState(0);
   const [fantasyTeamName, setFantasyTeamName] = useState('');

   const [isBoosterPurchaseAvailable, setIsBoosterPurchaseAvailable] = useState(false);
   const [activeStatusFromBackend, setActiveStatusFromBackend] = useState(false);
   const [isOngoingOrUpcomingAvailable, setIsOngoingOrUpcomingAvailable] = useState(false);
   const [tournamentDetails, setTournamentDetails] = useState(null);

   const handleShowBoosterPurchase = (player) => {
      const currentTime = new Date();

      if (isBoosterPurchaseAvailable) {
         onBoosterClick(player);
      } else if (!isOngoingOrUpcomingAvailable) {
         toast.warning(t("FANTASY.YOU_CANT_PURCHASE_A_BOOSTER_FOR_A_COMPLETED_TOURNAMENT"));
      } else if (new Date(tournamentDetails?.startTime) > currentTime) {
         toast.info("Team is still inactive. You will be able to boost your team once the tournament starts.", {toastId: FANTASY_WARNING_TOAST_ID})
      } else if (new Date(tournamentDetails?.endTime) > currentTime) {
         toast.info("Team is still inactive. You will be able to boost your team from next match onwards.", {toastId: FANTASY_WARNING_TOAST_ID})
      } else {
         toast.warning(t("FANTASY.YOU_CANT_PURCHASE_A_BOOSTER_FOR_A_COMPLETED_TOURNAMENT"), {toastId: FANTASY_WARNING_TOAST_ID})
      }
   }
   // get fantasy team of the user
   useEffect(() => {
      const fetchFantasyTeam = async () => {
         setIsFetchingFantasyTeam(true);
         getFantasyTeamByUsernameAndTournamentId(userName, tournamentId, accessToken)
            .then((response) => {
               if (response.apiStatus && response.team) {
                  const teamObj = response?.team.reduce((acc, player) => {
                     acc[player.playerId] = {...player, playerRole: player.playerRole};

                     // set captain and vice captain
                     if (player.isCaptain) {
                        setTeamCaptain(player);
                     } else if (player.isVcCaptain) {
                        setTeamViceCaptain(player);
                     }

                     return acc;
                  }, {});
                  setTotalPoints(response?.totalPoints || 0)
                  setFantasyTeamName(response?.teamName || '')
                  setFantasyTeamObj(teamObj);
                  setActiveStatusFromBackend(response?.activeStatus || false);
               }
            })
            .finally(() => {
               setIsFetchingFantasyTeam(false);
            });
      }

      fetchFantasyTeam();
   }, [userName, tournamentId, refreshFantasy, accessToken]);

   useEffect(() => {
      const fetchData = async () => {
         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "all", tournamentId, lang, Token)
            .then((response) => {

               if (response.apiStatus && response.totalElements !== 0) {
                  setIsOngoingOrUpcomingAvailable(true);
               } else {
                  setIsOngoingOrUpcomingAvailable(false);
               }
            });
      };
      fetchData();
   }, [Token, tournamentId, lang]);

   useEffect(() => {
      const fetchTournamentDetails = async () => {
         TournamentService.getTournamentById(tournamentId)
            .then((response) => {
               if (response.apiStatus) {
                  setTournamentDetails(response.tournament);
               }
            });
      };

      if (tournamentId) {
         fetchTournamentDetails();
      }
   }, [tournamentId]);

   useEffect(() => {
      const handleBoosterPurchaseAvailableStatus = async () => {
         if (activeStatusFromBackend && isOngoingOrUpcomingAvailable) {
            const currentTime = new Date();

            if (new Date(tournamentDetails?.startTime) < currentTime && new Date(tournamentDetails?.endTime) > currentTime) {
               setIsBoosterPurchaseAvailable(activeStatusFromBackend);
            } else {
               setIsBoosterPurchaseAvailable(false);
            }
         } else {
            setIsBoosterPurchaseAvailable(false);
         }
      }
      if (tournamentDetails) {
         handleBoosterPurchaseAvailableStatus();
      }
   }, [activeStatusFromBackend, isOngoingOrUpcomingAvailable, tournamentDetails]);

   const loadingIndicator = (<div style={{textAlign: "center"}}>
      <div className="lds-facebook">
         <div></div>
         <div></div>
         <div></div>
      </div>
   </div>);

   const PlayerCard = ({player, role}) => {
      const activeBooster = player?.boosterRulePointsDTOS?.find(booster => {
         const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
         if (booster?.active === true && new Date(endTime) > new Date()) {
            return booster
         }
      });

      const boosterTooltip = (element) => toolTipProvider(getBoosterRemainingTime(activeBooster), "top", element, true)

      return <div className={classNames(styles.Card)}>
         <div className={classNames(styles.CardBackground)}>
            <div className="row">

               {boosterTooltip(<div className={classNames(Styles.BoostRemainingTime, "BoostRemainingTime")}>
                  <svg viewBox="0 0 180 180" width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                     <path className="TimePath1" d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none"
                           stroke="#d9d9d9" stroke-width="8"></path>
                     <path className="TimePath2" style={{
                        stroke: activeBooster ? activeBooster?.colourCode : "transparent",
                        strokeDasharray: 540,
                        strokeDashoffset: getBoosterRemainingCircle(activeBooster),
                     }} d="m 90,4 a 86,86 0 0,1 0,172 a 86,86 0 0,1 0,-172" fill="none" stroke="rgb(0,255,194)"
                           stroke-linecap="round" stroke-width="8"
                     ></path>
                  </svg>
               </div>)}

               <a className={classNames(Styles.MoreInfoIconBtn, "MoreInfoIconBtn z-1")} role={"button"}
                  onClick={() => onInfoClick(player)}>
                  <img src={MoreInfoIcon}/>
               </a>
               <div className={classNames(styles.ProfilePic)}
                    style={{backgroundImage: `url(${player?.playerPictureURL})`}}>
               </div>
               {role !== "CAPTAIN" && role !== "VICE_CAPTAIN" &&
                  <div className={classNames(styles.BoostIconWrapper, "BoostIconWrapper")}
                       style={{
                          cursor: isBoosterPurchaseAvailable ? 'pointer' : 'not-allowed'
                       }}
                       onClick={() => handleShowBoosterPurchase(player)}>
                     <div className={classNames(styles.Circle, "Circle", !isBoosterPurchaseAvailable? "IconDisable" : "")}>
                        <div className={classNames(styles.InnerCircle, "InnerCircle")}></div>
                        <FlashIcon/>
                     </div>
                  </div>}
            </div>
            <div className={classNames(styles.TitleRow, "row")}>
               <h4>{player?.playerName}</h4>
               {role === "CAPTAIN" ? <span>{"(C)"}</span> : role === "VICE_CAPTAIN" ? <span>{"(VC)"}</span> : null}
            </div>
            <div className="PlayerPointsRow row">
               {(() => {
                  const playerPoints = getShowingFantasyPoints(player?.points);
                  return (<div
                     className={classnames("PlayerPoints", `${getFantasyPointsStatusClass(playerPoints?.sign)}`)}>
                     <span className={classNames(Styles.PointSplus, "PointSplus")}>{playerPoints.sign}</span>
                     <div
                        className={classNames(Styles.PointsWrapper, "PointsWrapper", playerPoints.intPart === 0 ? 'noPoints' : '')}>
                        {/*<span className={classNames(Styles.Points, "Points")}>{playerPoints.intPart}{playerPoints.decPart}</span>*/}
                        <span
                           className={classNames(Styles.DecimalPoints, "DecimalPoints")}>{playerPoints.intPart}{playerPoints.decPart}</span>
                     </div>
                  </div>);
               })()}
            </div>
         </div>
      </div>
   }

   return (<div
      className={Classnames("modal fade show", styles.modalBlur, styles.PaymentConfirmmodal, styles.ViewFantacyTeam)}
      id="exampleModalCenter"
      tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"
      style={isDisplayHidden ? {display: "none"} : {display: "block"}}>
      <div className={Classnames(styles.CreditPurchaseModal, "modal-dialog modal-dialog-centered")} role="document">
         <div className={Classnames("modal-content", styles.modalContentContainer)}>
            <button className={Classnames(styles.closeButton, "close z-3")} type="button" onClick={handleModalClose}
                    data-dismiss="modal" aria-label="Close">
                   <span className={styles.closeButton} aria-hidden="true">
                      <img alt='Close Icon' src="https://storage.googleapis.com/meta11/serviceplatform/closeIcon.webp"/>
                   </span>
            </button>
            <div
               className={Classnames(styles.UserProfileModalWrapper, styles.NoFantasyTeamModalWrapper, "NoFantasyTeamModalWrapper")}>
               <div className={Classnames(styles.ellipse, styles.eMulticolorHalf)}></div>
               <div className={Classnames(styles.ellipse, styles.e392)}></div>
               <div className="row p-0">
                  <div className={Classnames(styles.UserProfileModal, "col-md-12")}>
                     {isFetchingFantasyTeam ? loadingIndicator : (Object.keys(fantasyTeamObj).length < 1 ? <h4
                           className={Classnames(styles.NoFantasyTeamTitle)}>{t("FANTASY.YOU_DONT_HAVE_A_FANTASY_TEAM")}</h4> :
                        <div className={Classnames(styles.TitleWrapper)}>
                           <h4>{fantasyTeamName}</h4>

                           <div className={Classnames(styles.TeamCaptains, "row")}>
                              <div className="col-md-12">
                                 <div className={Classnames(styles.CaptainRow)}>
                                    {teamCaptain && <PlayerCard player={teamCaptain} role="CAPTAIN"/>}
                                    {teamViceCaptain && <PlayerCard player={teamViceCaptain} role="VICE_CAPTAIN"/>}
                                 </div>
                              </div>

                              <div className={Classnames(styles.OtherPlayers, "row")}>
                                 <div className="col-md-12">

                                    {Object.values(fantasyTeamObj).map(player => {
                                       if (!player.isCaptain && !player.isVcCaptain) {
                                          return (<PlayerCard player={player}/>)
                                       } else {
                                          return null;
                                       }
                                    })}

                                 </div>
                              </div>

                              <div className={Classnames(styles.TotalEarningRow, "row")}>
                                 <div className="col-md-12">
                                    <div className={Classnames(styles.TotalEarningWrapper)}>
                                       <div className={Classnames(styles.TotalEarning)}>
                                          <div className={Classnames(styles.Title)}>
                                             {t("FANTASY.TOTAL_EARNING")}:
                                          </div>
                                          <div className={Classnames(styles.SprtsToken)}>
                                             <img
                                                src={SprtsToken}/>{totalPoints ? getSprtsValueFromPoints(totalPoints, XP_TO_SPRTS_RATIO) : 0}
                                          </div>
                                          <div className={Classnames(styles.OmniPoints)}>
                                             <img src={OmniPoints}/>{formatNumberToKWithoutRounding(totalPoints)}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>)}
                  </div>
               </div>

               <div className="row p-0">
                  <div className={Classnames(styles.ModalButtonRow, "row p-0")}>
                     <button className={Classnames(styles.NoFantasyCloseBtn, "lightGreenButton")}
                             onClick={handleModalClose}>
                        <span>{t("FANTASY.CLOSE")}</span>
                     </button>
                  </div>
               </div>
               <div className={Classnames(styles.ellipse, styles.eMulticolorFull)}></div>
            </div>
         </div>
      </div>
   </div>);
}

const mapStateToProps = (state) => {
   return {
      Token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(ViewFantasyTeamModal);
