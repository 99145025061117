import React, {useEffect, useRef, useState} from "react";
import Footer from "../../../components/Footer/Footer";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import TournamentMatches from "../../../components/Tournament/TournamentMatches";
import Styles from "./LeagueTournament.module.scss";
import classNames from "classnames";
import Classnames from "classnames";
import NavbarSecondary from "../../../components/NavigationBar/NavigationBar";
import FirstPlace from "../../../assets/images/backgrounds/tournaments/1st_Prize.svg";
import SecondPlace from "../../../assets/images/backgrounds/tournaments/2nd_Prize.svg";
import ThirdPlace from "../../../assets/images/backgrounds/tournaments/3rd_Prize.svg";
import Line from "../../../assets/images/line.webp";
import Credit from "../../../assets/images/icons/24_sprts_token.webp";
import * as TournamentService from "../../../services/TournamentService";
import {BsCheck2, BsDash, BsX} from "react-icons/bs";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import PointsIcon1 from "../../../assets/images/points_icn_1.webp";
import PointsIcon2 from "../../../assets/images/points_icn_2.webp";
import * as StatisticsService from "../../../services/StatisticsService";
import Calendar from "../../../assets/images/fi_calendar.webp";
import {formatDate, formatTime} from "../../../helpers/CommonFunctions";
import WinPrizesSection from "../../../components/Tournament/WinPrizesSection/WinPrizesSection";
import TournamentLeaderBoard from "../../../components/Tournament/Leaderboard/TournamentLeaderBoard";
import Modal from "react-bootstrap/Modal";
import HowToWinRewards from "../../../components/Modals/HowToWinRewards/HowToWinRewards";
import PubNubMatchesHandler from "../../../components/Tournament/PubNubMatchesHandler";
import {MATCH_CARD_TYPES, SOCKET_MESSAGES, TOURNAMENT_TYPES} from "../../../utilities/Constants";
import * as PubNubService from "../../../services/PubNubService";
import LatestMatch from "../LatestMatch";
import TournamentPubnubHandler from "../../../services/PubNubHandlers/TournamentPubNubHandler";
import PointTable from "../../../components/Tournament/PointTable/PointTable";
import * as actions from "../../../store/actions";
import TournamentFantasySection from "../../../components/Tournament/FantasyTeamSection/TournamentFantasySection";
import {checkUserFantasySubscription} from "../../../services/FantasyService";
import * as urlParams from "date-fns";
import useTournamentValidator from "../../../helpers/useTournamentValidator";
import LineChart from "../../../components/LeaderBoardLIneChart/LeaderBoardLIneChart"

const LeagueTournament = (props) => {

   //Local State Management
   const [toggleDetails, setToggleDetails] = useState(true);
   const [selectedOption, setSelectedOption] = useState(sessionStorage?.getItem("SelectedOption")||'Matches');
   const [latestMatch, setLatestMatch] = useState(null);
   const [tournamentDetails, setTouranamentDetails] = useState(null);
   const [tournamentTeams, setTournamentTeams] = useState(null);
   const [pointsLoaded, setPointsLoaded] = useState("loading");
   let {tournamentId} = useParams();
   const [isHowToModalOpen, setIsHowToModalOpen] = useState(false);
   const [currentTeamOne, setcurrentTeamOne] = useState();
   const [currentTeamTwo, setcurrentTeamTwo] = useState();
   const [currentTeamOneData, setCurrentTeamOneData] = useState();
   const [currentTeamTwoData, setCurrentTeamTwoData] = useState();
   const {t} = useTranslation();
   const [translationsLoaded, setTranslationsLoaded] = useState(false);
   const [rewardsCategory, setRewardsCategory] = useState()
   const [lang, setLang] = useState(localStorage.getItem("language"));
   const [currentPage, setCurrentPage] = useState(0);
   const [postsPerPage, setpostsPerPage] = useState(5);
   const [connectPubnub, setConnectPubnub] = useState(false);
   const [token, setToken] = useState(null);
   const [selectedRadioBtnValue, setSelectedRadioBtnValue] = useState('all');
   const [searchQuery, setSearchQuery] = useState('');
   const [subscribeChannel, setSubscribeChannel] = useState(null);
   const [unSubscribeChannel, setUnsubscribeChannel] = useState(null);
   let uuid = localStorage.getItem("uuid");
   const [loadingInProgress, setLoading] = useState(true);
   const pathname = window.location.pathname;
   let navigate = useNavigate();
   const currentTime = new Date();
   const matchStartTime = new Date(latestMatch?.matchStartTime);
   const [pubnubRewardsToken, setPubnubRewardsToken] = useState(null)
   const [connectRewardsPubnub, setConnectRewardsPubnub] = useState(null);
   const [pubnubLeaderboardToken, setPubnubLeaderboardToken] = useState(null)
   const [connectLeaderboardPubnub, setConnectLeaderboardPubnub] = useState(null);
   const location = useLocation();
   const [leaderBoardData, setLeaderBoardData] = useState();
   const [leaderBoardUserData, setLeaderBoardUserData] = useState();
   const [availbleUpcomingMatch, setAvailbleUpcomingMatch] = useState(true)
   const [refreshKey, setRefreshKey] = useState(0);

   const fantasyLeaderboardRef = useRef(null);

   // check if the user is registered to a tournament (for paid tournaments)
   const {isFantasySubscribed} = useTournamentValidator({
      tournamentDetails: tournamentDetails, userName: props.userName, Token: props.Token
   });

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      const fetchData = async () => {
         if (props.isAuthenticated) {
            try {
               PubNubService.requestAccessTokenQuiz(props.userName, '1234', Number(tournamentId), props.Token).then(response => {
                  if (response != null) {
                     setPubnubRewardsToken(response);
                     setConnectRewardsPubnub(true);
                     setPubnubLeaderboardToken(response);
                     setConnectLeaderboardPubnub(true);
                     localStorage.setItem("pubnubRewardToken", response);
                  }
               });
               // setToken(response);
               // pubnub.setAuthKey(response); // Update PubNub authKey
            } catch (error) {
               console.error("Error:", error);
            }
         }
      }

      fetchData();
   }, [props.userName, loadingInProgress]);

   const loadingIndicator = (
      <div className="w-100 text-center">
         <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
         }}>
            <div className="lds-facebook">
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>);

   useEffect(() => {
      const fetchTournamentTeams = async () => {
         return await TournamentService.getTournamentTeams(props.Token, tournamentId, lang).then((response) => {
            if (response.apiStatus) {
               setTournamentTeams(response.teams);
               if (response?.teams) {
                  setPointsLoaded(true);
               } else {
                  setPointsLoaded(false);
               }
            } else {
               setPointsLoaded(true);
            }
         });
      };

      fetchTournamentTeams();

   }, [selectedOption]);

   useEffect(() => {

      const fetchTournamentDetails = async () => {
         return await TournamentService.getTournamentById(tournamentId).then((response) => {
            if (response.apiStatus) {
               setTouranamentDetails(response.tournament);
            }
         });
      };

      fetchTournamentDetails();
   }, [selectedOption, rewardsCategory]);

   // useEffect(() => {
   //    const checkTournamentRegistration = async () => {
   //       sessionStorage.setItem('redirectPath', pathname);
   //       if (!localStorage.getItem('accessToken')) {
   //          navigate("/login");
   //       } else {
   //          try {
   //             const response = await TournamentService.checkTournamentRegistration(tournamentId, props.userName);
   //             if (response.apiStatus) {
   //                if (!response.available) {
   //                   navigate("/tournaments");
   //                }
   //
   //                if (new Date(tournamentDetails?.startTime) > new Date()) {
   //                   const isSubscribed = await handleCheckUserFantasySubscription(tournamentId);
   //                   const searchParams = new URLSearchParams(window.location.search);
   //                   const isReviewSchedule = searchParams.has('reviewSchedule') && searchParams.get('reviewSchedule') === 'true';
   //
   //                   setIsFantasySubscribed(isSubscribed);
   //
   //                   if (isReviewSchedule) {
   //                      return
   //                   } else if (isSubscribed === false && tournamentDetails.toStart === false) {
   //                      navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: {id:tournamentId,tournamentType:TOURNAMENT_TYPES.LEAGUE}}});
   //                      return;
   //                   }
   //                }
   //             } else {
   //                navigate("/tournaments");
   //             }
   //
   //          } catch (error) {
   //             console.log(error);
   //             throw error;
   //          }
   //       }
   //
   //    }
   //    checkTournamentRegistration();
   // }, [tournamentId, props.userName,tournamentDetails]);

   useEffect(() => {

      const fetchData = async () => {
         if (localStorage.getItem("pubnubToken") == null) {
            try {
               const response = await PubNubService.requestAccessTokenMatches(uuid, props.Token).then(response => {
                  if (response != null) {
                     setToken(response);
                     setConnectPubnub(true);
                     console.log(response);
                     localStorage.setItem("pubnubToken", response);
                  }
               })

            } catch (error) {
               console.error("Error:", error);
            }
         } else {
            setConnectPubnub(true);
            setToken(localStorage.getItem("pubnubToken"));
         }
      };
      fetchData();
   }, [uuid, selectedOption]);

   useEffect(() => {
      const fetchData = async (teamOneId, teamTwoId) => {
         if (teamOneId) {
            StatisticsService.getTeamMatchesHistoryById(tournamentId, teamOneId)
               .then((response) => {
                  if (response.apiStatus) {
                     setCurrentTeamOneData(response);
                  }
               });
         }

         if (teamTwoId) {
            StatisticsService.getTeamMatchesHistoryById(tournamentId, teamTwoId)
               .then((response) => {
                  if (response.apiStatus) {
                     setCurrentTeamTwoData(response);
                  }
               });
         }
      };
      fetchData(currentTeamOne, currentTeamTwo);
   }, [currentTeamOne, currentTeamTwo, selectedOption, tournamentId]);


   useEffect(() => {
      setToggleDetails(selectedOption === 'Details' ? true : false);
   }, [selectedOption]);

   useEffect(() => {
      const fetchData = async () => {
         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "upcoming", tournamentId, lang, props.Token)
            .then((response) => {
               if (response.apiStatus && response.totalElements == 0) {
                  setAvailbleUpcomingMatch(false);
               }
            });
      };
      fetchData();
   }, [latestMatch,selectedOption,tournamentDetails]);

   useEffect(() => {

      const fetchData = async () => {
         await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "all", tournamentId, lang, props.Token)
            .then(async (response) => {
               if (response.apiStatus && response.totalElements != 0) {
                  if (response?.matches?.[0]) {
                     setLatestMatch(response?.matches[0])
                     if (response.matches[0]) {
                        setcurrentTeamOne(response.matches[0].team1Id);
                        setcurrentTeamTwo(response.matches[0].team2Id);
                     }
                  } else {
                     setLatestMatch(null);
                  }
               } else if (response.totalElements == 0) {
                  setTranslationsLoaded(true);
                  await StatisticsService.getAllMatchCardsByTournamentId("", 0, 1, "isLive,isScheduled,matchId,desc", "completed", tournamentId, lang, props.Token)
                     .then((response) => {
                        if (response.apiStatus && response.totalElements != 0) {
                           if (response?.matches?.[0]) {
                              setLatestMatch(response?.matches[0])
                              if (response.matches[0]) {
                                 setcurrentTeamOne(response.matches[0].team1Id);
                                 setcurrentTeamTwo(response.matches[0].team2Id);
                              }
                           } else {
                              setLatestMatch(null);
                           }
                        }

                     })
               }
            });
      };
      fetchData();
   }, [selectedOption]);

   useEffect(() => {
      const fetchData = async () => {
         await TournamentService.getUserRewardCategory(tournamentId)
            .then((response) => {
               if (response.apiStatus && response.rewardCategoryList) {
                  setRewardsCategory(response.rewardCategoryList);
               }
            })
      };
      fetchData();
   }, [tournamentId, selectedOption]);

   function getRewardForPosition(position, rewardCategoryList) {

      if (!Array.isArray(rewardCategoryList)) {
         return 0;
      }
      for (const category of rewardCategoryList) {
         const {minRank, maxRank, reward} = category.userRanking;
         if (position >= minRank && position <= maxRank) {
            if (tournamentDetails.tournamentTicketOmniCredits) {
               return reward;
            } else {
               return (reward / 250).toFixed(3);
            }
         }
      }
      return 0;
   }

   const returnLatestMatch = () => {
      return (
         <LatestMatch tournamentDetails={tournamentDetails} latestMatch={latestMatch}/>
      )
   }

   const handleCheckUserFantasySubscription = async (tournamentId) => {
      try {
         const response = await checkUserFantasySubscription(tournamentId, props.userName, props.Token);
         if (response.apiStatus && response?.isSubscribed) {
            return response?.isSubscribed;
         } else if (response.apiStatus) {
            return false;
         }
         return null;
      } catch (error) {
         console.error("handleCheckUserFantasySubscription", error);
      }
   };

   useEffect(() => {
      returnLatestMatch();
   }, [latestMatch]);

   useEffect(() => {
      if (tournamentDetails?.active === false && tournamentDetails?.toStart === false) {

         const urlParams = new URLSearchParams(location.search);
         urlParams.set('type', MATCH_CARD_TYPES.COMPLETED);
         navigate({
            pathname: location.pathname,
            search: urlParams.toString()
         });
         setSelectedRadioBtnValue(MATCH_CARD_TYPES.COMPLETED);
      }
   }, [tournamentDetails]);


   const openHowtoWinRewardsModal = () => {
      setIsHowToModalOpen(true);
   }

   function handleMoreInfoClose() {
      setIsHowToModalOpen(false);
   }

   const overCount = (teamOvers, teamBalls) => {

      const overs = teamBalls !== 6
         ? teamOvers - 1
         : teamOvers

      const balls = teamBalls !== 6
         ? teamBalls
         : 0
      return overs + "." + balls;

   }
   const getMatchStatus = (matchStatus) => {
      if (matchStatus) {
         switch (matchStatus) {
            case "WIN":
               return (<div className="matchIndicator_tournament greenInd"><BsCheck2/></div>);
               break;
            case "LOST":
               return (<div className="matchIndicator_tournament purpleInd"><BsX/></div>);
               break;
            case "DRAW":
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
            default:
               return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
               break;
         }
      } else {
         return (<div className="matchIndicator_tournament grayInd"><BsDash/></div>);
      }
   };

   const winPrices = (winPriceData) => {
      if (winPriceData?.tournamentRank == 1) {
         return (
            <>
               <div className={classNames(Styles.FirstPlace)}>
                  <img src={FirstPlace} alt=''/>
                  <div className={classNames(Styles.CreditWrapper)}>
                     <div>
                        <img src={Credit} alt=''/>
                        &nbsp;
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={classNames(Styles.LineWrapper)}>
                  <img src={Line} alt=''/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 2) {
         return (
            <>
               <div className={classNames(Styles.SecondPlace)}>
                  <img src={SecondPlace} alt=''/>
                  <div className={classNames(Styles.CreditWrapper)}>
                     <div>
                        <img src={Credit} alt=''/>
                        &nbsp;
                        {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                     </div>
                  </div>
               </div>
               <div className={classNames(Styles.LineWrapper)}>
                  <img src={Line} alt=''/>
               </div>
            </>

         );
      } else if (winPriceData?.tournamentRank == 3) {
         return (
            <div className={classNames(Styles.ThirdPlace)}>
               <img src={ThirdPlace}/>
               <div className={classNames(Styles.CreditWrapper)}>
                  <div>
                     <img src={Credit}/>
                     &nbsp;
                     {getRewardForPosition(winPriceData?.tournamentRank, rewardsCategory)}
                  </div>
               </div>
            </div>
         )
      }
   }
   if (!translationsLoaded) {
      return loadingIndicator;
   }
   const messageFromMatchesCallback = (message) => {
      try {
         let json = JSON.parse(message);
         console.log(json);
         setRefreshKey(prevKey => prevKey + 1);

         if (currentPage == 0 && searchQuery == "") {

            if (selectedRadioBtnValue == MATCH_CARD_TYPES.ALL || selectedRadioBtnValue == MATCH_CARD_TYPES.LIVE) {

               if (json.bodyType == SOCKET_MESSAGES.MATCH_CARD_DETAILS) {
                  setLatestMatch(json.body?.matches?.[0]);
               } else {
                  setLoading(false);
               }
            }
         } else if (currentPage != 0) {
            if (selectedRadioBtnValue != MATCH_CARD_TYPES.ALL || selectedRadioBtnValue != MATCH_CARD_TYPES.LIVE) {
               setUnsubscribeChannel("Matches-" + tournamentId);
               setUnsubscribeChannel("Live-" + tournamentId)
            }
         }

      } catch (error) {
         console.error("JSON parsing error:", error);
      }
   }

   const messageFromRewardsCallback = (message) => {
      if (message) {
         setRewardsCategory(message.rewardCategoryList);
         console.log(message.rewardCategoryList)
      } else {
         console.log(message);
      }
   }
   const messageFromUserLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardUserData(json.body)
      } else {
         console.log(message);
      }
   }
   const messageFromLeaderboardCallback = (message) => {
      if (message) {
         console.log(message)
         let json = JSON.parse(message);
         setLeaderBoardData(json.body)
      } else {
         console.log(message);
      }
   }
   const callbackFromTeamPoints = (message) => {
      if (message) {
         console.log(message)
         setTournamentTeams(message?.teams);
      } else {
         console.log(message);
      }
   }
   const pageAndSizeFromLeaderboard = (data) => {
      console.log(data)
      setCurrentPage(data.page);
      setpostsPerPage(data.size)
   }

   const changeSelectedOption = (data) =>{
      setSelectedOption(data);
      sessionStorage.setItem("SelectedOption", data);
   }
   const redirectToFantasy  = () => {
      navigate(`/fantasy-team/${tournamentId}`, {state: {tournamentDetails: {id:tournamentId,tournamentType:TOURNAMENT_TYPES.LEAGUE}}});
      return;
   }
   return (
      <React.Fragment>
         {(connectPubnub && token != null && tournamentId != null || undefined) ?
            <PubNubMatchesHandler onRecieved={messageFromMatchesCallback} token={token} subscribe={subscribeChannel}
                                  onUnsubscribe={unSubscribeChannel} tournamentId={tournamentId}/> : <></>}
         {(connectRewardsPubnub && pubnubRewardsToken != null && props.isAuthenticated && props.userName) ?
            <TournamentPubnubHandler onTeamPoints={callbackFromTeamPoints} onDataReceived={messageFromRewardsCallback} onUserLeaderboardReceived={messageFromUserLeaderboardCallback} onLeaderboardReceived={messageFromLeaderboardCallback} token={pubnubRewardsToken} tournamentId={tournamentId}
                                     userName={props.userName} page={currentPage} size={postsPerPage}/> : <></>}

         <div className={classNames(Styles.Radial1)}></div>
         <div className={classNames(Styles.Radial2)}></div>
         <div className={classNames(Styles.LeagueTournamentPage,"LeagueTournamentPage")}>
            <div className={classNames(Styles.bodyTournaments)}>
               <div className={classNames(Styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
                  <div className="col-12 p-0 row">
                     <NavbarSecondary page="events"/>
                  </div>
                  <div className={classNames(Styles.pageWrapper, "col-12 p-0")}>
                     <h1>{tournamentDetails?.name}</h1>
                     <div className={classNames(Styles.contnetWrapper)}>
                        <div className="row">
                           <div className={classNames(Styles.MainContnetWrapper, "col-md-12 p-0- float-left pe-lg-4 p-0")}>
                              {/* Daily MatchesWrapper */}
                              <div className={classNames(Styles.DailyMatchesWrapper, Styles.MatchWrapper)}>
                                 <div className="row">
                                    <div className="col-md-12 p-0">
                                       {/*<div className="row">*/}
                                       {/*   <div className="col-md-12">*/}
                                       {/*      <h1>LIMITED TOURNAMENT</h1>*/}
                                       {/*   </div>*/}
                                       {/*</div>*/}
                                       <div className="row">
                                          <div className="col p-0">
                                             <div className={classNames(Styles.LimitedTournamentHeader)} style={{background: `url(${tournamentDetails?.logoUrl})`, backgroundRepeat: 'no-repeat'}}>
                                                <div className="row">
                                                   <div className="col-md-12">
                                                      {returnLatestMatch()}
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="row p-0">
                                    <div className="col-md-4 p-0">
                                       <div className={classNames(Styles.MatchOrDetailsWrapper,"MatchOrDetailsWrapper")}>
                                          <div className={classNames(Styles.RadioBtnWrapper)}>
                                             <label className={classNames(Styles.radioButtonlbl,"radioButtonlbl order-sm-2 order-2 m-0 p-0 mb-2 mb-sm-0")}>
                                                <input type="radio" id="Fantasy" name="Details_or_Matches"
                                                       checked={selectedOption === "Fantasy"}
                                                       value="Fantasy"
                                                       onChange={() =>changeSelectedOption('Fantasy')}/>
                                                <span className="p-3"
                                                      htmlFor={"Fantasy"}>{t("LEAGUE_TOURNAMENT.FANTASY")}</span>
                                             </label>
                                          </div>
                                          <div className={classNames(Styles.RadioBtnWrapper)}>
                                             {/* <input type="radio" id="Details" name="Details_or_Matches"
                                                    checked={selectedOption == "Details" ? true : false} value="Details"
                                                    onChange={() => setSelectedOption('Details')}/>
                                             <label htmlFor={"Details"}>{t("LEAGUE_TOURNAMENT.DETAILS")}</label> */}


                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 p-0 mb-2 mb-sm-0">
                                                <input type="radio" id="Details" name="Details_or_Matches"
                                                       checked={selectedOption == "Details" ? true : false} value="Details"
                                                       onChange={() =>changeSelectedOption('Details')}/>
                                                <span className="p-3" htmlFor={"Details"}>{t("LEAGUE_TOURNAMENT.DETAILS")}</span>
                                             </label>

                                          </div>

                                          <div className={classNames(Styles.RadioBtnWrapper)}>
                                             {/* <input type="radio" id="Matches" name="Details_or_Matches" value="Matches"
                                                    checked={selectedOption == "Matches" ? true : false}
                                                    onChange={() => setSelectedOption('Matches')}/>
                                             <label htmlFor={"Matches"}>{t("LEAGUE_TOURNAMENT.MATCHES")}</label> */}


                                             <label className="radioButtonlbl order-sm-2 order-2 m-0 p-0 mb-2 mb-sm-0">
                                                <input type="radio" id="Matches" name="Details_or_Matches" value="Matches"
                                                       checked={selectedOption == "Matches" ? true : false}
                                                       onChange={() =>changeSelectedOption('Matches')}/>
                                                <span className="p-3" htmlFor={"Matches"}>{t("LEAGUE_TOURNAMENT.MATCHES")}</span>
                                             </label>

                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="row">
                                    <div className="col-xl-9 col-lg-12 float-left p-0">
                                       {(selectedOption === "Fantasy") &&
                                          <>
                                             <TournamentFantasySection tournamentDetails={tournamentDetails} refreshKey={refreshKey}/>
                                             <div ref={fantasyLeaderboardRef}>
                                                <TournamentLeaderBoard
                                                   leaderboardData={leaderBoardData}
                                                   userLeaderBoardData={leaderBoardUserData}
                                                   tournamentDetails={tournamentDetails}
                                                   onPageAndSize={pageAndSizeFromLeaderboard}/>
                                             </div>
                                             <div>
                                                <LineChart tournamentDetails={tournamentDetails}/>
                                             </div>
                                          </>
                                    }
                                       {!toggleDetails && (selectedOption !== "Fantasy") &&
                                          <TournamentMatches tournamentId={tournamentId}/>}

                                       {(selectedOption == "Details") &&
                                          <>
                                             <PointTable tournamentTeams={tournamentTeams} pointsLoaded={pointsLoaded}/>
                                             <div className="row p-0">
                                                <h2>{t("LEAGUE_TOURNAMENT.CURRENT_STATUS_OF_GAME")}</h2>
                                             </div>
                                             <div className="row p-0">
                                                <div className={classNames(Styles.MatchDetails, "col p-0 m-0")}>
                                                   <div className={classNames(Styles.WinPrizes)}>
                                                      <div className={classNames(Styles.Conner1)}></div>
                                                      <div className={classNames(Styles.Conner2)}></div>
                                                      <div className={classNames(Styles.Conner3)}></div>
                                                      <div className={classNames(Styles.Conner4)}></div>

                                                      <div className={classNames(Styles.Shade1)}></div>
                                                      <div className={classNames(Styles.Shade2)}></div>
                                                      <div className={classNames(Styles.Shade3)}></div>
                                                      <div className={classNames(Styles.Shade4)}></div>

                                                      <div className={classNames(Styles.WinPrizesWrapper, Styles.CurrentStatusOfGame)}>
                                                         <div className={classNames(Styles.Tournament)}>
                                                            {latestMatch ?
                                                               <div className={classNames(Styles.TournamentView)}>
                                                                  <div className="row">
                                                                     <div className={classNames(Styles.PlayerContainerWrapper, "col-md-6")}>
                                                                        <Link to={{
                                                                           pathname: `/team/${currentTeamOneData?.teamId}`,
                                                                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                                        }} style={{textDecoration: "none"}}>
                                                                           <div className={classNames(Styles.PlayerContainer)}>
                                                                              <div className={classNames(Styles.LogoContainer)}>
                                                                                 <img src={currentTeamOneData?.pictureURL}/>
                                                                              </div>
                                                                              <h4 style={{color: "white"}}>{currentTeamOneData?.name}</h4>
                                                                              <div className="body-text4 color-gray2">{currentTeamOneData?.wins} {t("LEAGUE_TOURNAMENT.WINS")} • {currentTeamOneData?.losses} {t("LEAGUE_TOURNAMENT.LOSES")}</div>
                                                                              <div className={classNames(Styles.PointsWrapper)}>
                                                                                 {currentTeamOneData?.matchHistoryDTOS?.map((match, i) =>
                                                                                    match?.matchStatus == "LOST" ? <img src={PointsIcon1}/> : <img src={PointsIcon2}/>
                                                                                 )}
                                                                              </div>
                                                                           </div>
                                                                        </Link>
                                                                     </div>
                                                                     <div className={classNames(Styles.PlayerContainerWrapper, "col-md-6")}>
                                                                        <Link to={{
                                                                           pathname: `/team/${currentTeamTwoData?.teamId}`,
                                                                           search: `returnUrl=${encodeURIComponent(location.pathname)}`
                                                                        }} style={{textDecoration: "none"}}>
                                                                           <div className={classNames(Styles.PlayerContainer)}>
                                                                              <div className={classNames(Styles.LogoContainer)}>
                                                                                 <img src={currentTeamTwoData?.pictureURL}/>
                                                                              </div>
                                                                              <h4 style={{color: "white"}}>{currentTeamTwoData?.name}</h4>
                                                                              <div className="body-text4 color-gray2">{currentTeamTwoData?.wins} wins • {currentTeamTwoData?.losses} loses</div>
                                                                              <div className={classNames(Styles.PointsWrapper)}>
                                                                                 {currentTeamTwoData?.matchHistoryDTOS?.map((match, i) =>
                                                                                    match?.matchStatus == "LOST" ? <img src={PointsIcon1}/> : <img src={PointsIcon2}/>
                                                                                 )}
                                                                              </div>
                                                                           </div>
                                                                        </Link>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               : <span>{t("LEAGUE_TOURNAMENT.NO_MATCHES_ARE_BEING_PLAYED_AT_THE_MOMENT")}</span>}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </>}
                                    </div>

                                    <div className={classNames(Styles.rightCol, "col-xl-3 p-0 d-xl-block")}>
                                       {/* Rewards Details */}
                                       {tournamentDetails?.tournamentTicketOmniCredits ?
                                          (rewardsCategory?.[0]?.userRanking?.reward > 0) ?
                                             (tournamentDetails?.active === false && tournamentDetails?.toStart === true) ?
                                                <div className="row p-0">
                                                   <div className="col p-0">
                                                      <div className={Classnames(Styles.WinPrizes)}>
                                                         <div className={Classnames(Styles.Conner1)}></div>
                                                         <div className={Classnames(Styles.Conner2)}></div>
                                                         <div className={Classnames(Styles.Conner3)}></div>
                                                         <div className={Classnames(Styles.Conner4)}></div>
                                                         <div className={Classnames(Styles.Shade1)}></div>
                                                         <div className={Classnames(Styles.Shade2)}></div>
                                                         <div className={Classnames(Styles.Shade3)}></div>
                                                         <div className={Classnames(Styles.Shade4)}></div>
                                                         {tournamentDetails?.winPrices?.length > 0 ? <>
                                                               <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                                  {tournamentDetails?.winPrices?.map((price, i) => winPrices(price))}
                                                                  <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                               </div>
                                                            </>
                                                            : <div className={Classnames(Styles.WinPrizesWrapper)}>
                                                               {t("LEAGUE_TOURNAMENT.TAKE_PART_IN_THE_TOURNAMENT_TO_WIN_REWARDS")}
                                                               <button onClick={openHowtoWinRewardsModal} className={Classnames(Styles.playNow, 'mt-4')}>{t("LEAGUE_TOURNAMENT.HOW_TO_WIN_REWARDS")}</button>
                                                            </div>
                                                         }
                                                      </div>
                                                   </div>
                                                </div>
                                                : <></>
                                             : <></>
                                          : <></>
                                       }

                                       {/* Winners Section */}
                                       {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                          <WinPrizesSection tournament={tournamentDetails} onBtnClick={() => {
                                             if (selectedOption !== "Fantasy") {
                                                changeSelectedOption("Fantasy")
                                                setTimeout(() => {
                                                   if (fantasyLeaderboardRef.current) {
                                                      fantasyLeaderboardRef.current.scrollIntoView({behavior: "smooth"});
                                                   }
                                                }, 1000)
                                             } else {
                                                if (fantasyLeaderboardRef.current) {
                                                   fantasyLeaderboardRef.current.scrollIntoView({behavior: "smooth"});
                                                }
                                             }
                                          }}/>
                                          : <></>}

                                       {/* create fantasy button */}
                                       {(tournamentDetails?.active === false && tournamentDetails?.toStart === true) && (selectedOption !== "Fantasy") && !isFantasySubscribed && availbleUpcomingMatch?
                                          <div className="row p-0">
                                             <div className="col p-0">
                                                <div className={classNames(Styles.OverviewWrapper)}>
                                                   <div className={classNames(Styles.WinPrizes)}>
                                                      <div className={classNames(Styles.WinPrizesWrapper)}>
                                                         <div className="row">
                                                            <button className={"GrayButton color-white mb-3"} onClick={redirectToFantasy}><span>{t("LEAGUE_TOURNAMENT.CREATE_FANTASY_TEAM")}</span></button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div> : <></>}

                                       {/* Overview */}
                                       {(tournamentDetails?.active === false && tournamentDetails?.toStart === true) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                          <div className="row p-0">
                                             <div className="col p-0">
                                                <div className={classNames(Styles.OverviewWrapper)}>
                                                   <div className={classNames(Styles.WinPrizes)}>
                                                      <div className={classNames(Styles.Conner1)}></div>
                                                      <div className={classNames(Styles.Conner2)}></div>
                                                      <div className={classNames(Styles.Conner3)}></div>
                                                      <div className={classNames(Styles.Conner4)}></div>
                                                      <div className={classNames(Styles.Shade1)}></div>
                                                      <div className={classNames(Styles.Shade2)}></div>
                                                      <div className={classNames(Styles.Shade3)}></div>
                                                      <div className={classNames(Styles.Shade4)}></div>
                                                      <div className={classNames(Styles.WinPrizesWrapper)}>
                                                         <div className="row">
                                                            <h4>{t("LEAGUE_TOURNAMENT.OVERVIEW")}</h4>
                                                            <span className="body-text4 color-gray2"> {t("LEAGUE_TOURNAMENT.START")}: </span>
                                                            <div className={classNames(Styles.Date)}><img src={Calendar}/>
                                                               &nbsp;{formatDate(tournamentDetails?.startTime) || '--'}&nbsp;at&nbsp;{formatTime(tournamentDetails?.startTime) || '--'}</div>
                                                            <p className="body-text4 color-gray2">
                                                               {tournamentDetails?.bio ? tournamentDetails?.bio : t("LEAGUE_TOURNAMENT.NO_DESCRIPTION_AVAILABLE")}
                                                            </p>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div> : <></>}


                                       {(tournamentDetails?.active === false && tournamentDetails?.toStart === false) || (tournamentDetails?.active === true && tournamentDetails?.toStart === false) ?
                                          <div className="row p-0">
                                             <div className={classNames(Styles.bannerWrapper, "col p-0")}>
                                                <img
                                                   src="https://storage.googleapis.com/meta11/serviceplatform/limitedTournamentBanner.png"/>
                                             </div>
                                          </div> : <></>}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <Footer/>
               </div>
            </div>
         </div>

         <Modal show={isHowToModalOpen} onHide={handleMoreInfoClose}>
            <Modal.Body>
               <HowToWinRewards handleMoreInfoClose={handleMoreInfoClose}/>
            </Modal.Body>
         </Modal>
      </React.Fragment>
   );
};

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      email: state.auth.email,
      Token: state.auth.accessToken,
      RefreshToken: state.auth.refreshToken,
      timeOut: state.auth.sessionTimeout,
   };
};

const mapDispatchToProps = (dispatch) => {
   return {
      onLogout: () => dispatch(actions.logout()), onRefreshAuth: (accessToken, refreshToken) => dispatch(actions.refreshSession(accessToken, refreshToken)),
   };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueTournament);
