import {BOOSTER_EXTRA_TIME, NOTIFICATION_TYPES, SCREEN_SIZES, SITE_LANGUAGE, USER_PROFILE_CONSTANT} from "../utilities/Constants";
import {BattingStylesEN, BattingStylesHI, BowlingStylesEN, BowlingStylesHI, NotificationTypeLogos, PlayerHittingRanks, PlayerHittingRanksHI, PlayingRolesEN, PlayingRolesHI} from "../utilities/Enums";
import {refreshToken, validateToken} from "../services/UserService";
import {decryptData} from "./SecureLocalStorage";
import * as PubNubService from "../services/PubNubService";
import React, {useEffect, useState} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {toast} from "react-toastify";
import moment from "moment/moment";
import PlaceHolder1 from "../assets/images/FantasyProfilePic1.webp";
import PlaceHolder2 from "../assets/images/FantasyProfilePic2.webp";
import PlaceHolder3 from "../assets/images/FantasyProfilePic3.webp";
import PlaceHolder4 from "../assets/images/FantasyProfilePic4.webp";


export function formatDate(dateString, timeOnly, short) {
   if (dateString) {
      const language = localStorage.getItem("language") === 'en' ? "en-US" : "hi-IN";
      const date = new Date(dateString);
      const options = {
         month: "long",
         day: "numeric",
         year: "numeric",
      };
      const formattedDate = date.toLocaleDateString(language, options);
      if (formattedDate != "Invalid Date" && !timeOnly) {
         return formattedDate;
      } else if (timeOnly) {
         let option;
         if (short) {
            option = {
               month: "short",
               day: "numeric",
            };
         } else {
            option = {
               month: "long",
               day: "numeric",
            };
         }
         const formatteDate = date.toLocaleDateString(language, option);
         return formatteDate;
      } else {
         return "---";
      }
   } else {
      return "---"
   }
}

export function formatTime(dateString) {
   if (dateString) {
      const date = new Date(dateString);
      const options = {
         hour: "numeric",
         minute: "numeric",
         hour12: true,
      };
      const formattedTime = date.toLocaleTimeString("en-US", options);
      if (formattedTime != "Invalid Date") {
         return formattedTime;
      } else {
         return "---";
      }
   } else {
      return '---';
   }
}

export function formatDateForTournament(inputDate) {
   const date = moment(inputDate);
   const month = date.format('MMMM').toUpperCase();
   const day = date.format('Do');
   const time = date.format('hA');

   return `${month} ${day} ${time}`;
}

export function getNotificationTypeIcon(notificationType) {
   let iconSrc = null;
   switch (notificationType) {
      case NOTIFICATION_TYPES.COMMUNITY:
         iconSrc = NotificationTypeLogos.COMMUNITY_LOGO;
         break;
      case NOTIFICATION_TYPES.FAVORITE_PLAYER:
         iconSrc = NotificationTypeLogos.FAVORITE_PLAYER_LOGO;
         break;
      case NOTIFICATION_TYPES.FAVOURITE_TEAM:
         iconSrc = NotificationTypeLogos.FAVOURITE_TEAM_LOGO;
         break;
      case NOTIFICATION_TYPES.QUESTIONNAIRE:
         iconSrc = NotificationTypeLogos.QUESTIONNAIRE_LOGO;
         break;
      case NOTIFICATION_TYPES.PASSWORD_CHANGE:
         iconSrc = NotificationTypeLogos.PASSWORD_CHANGE_LOGO;
         break;
      case NOTIFICATION_TYPES.MATCH_START:
         iconSrc = NotificationTypeLogos.MATCH_START_LOGO;
         break;
      case NOTIFICATION_TYPES.OMNI_PURCHASE:
         iconSrc = NotificationTypeLogos.MATCH_START_LOGO;
         break;
      case NOTIFICATION_TYPES.LEADERBOARD_RANK_REWARD:
         iconSrc = NotificationTypeLogos.LEADERBOARD_RANK_REWARD;
         break;
      case NOTIFICATION_TYPES.FANTASY_PLAYER_BOOSTER_EXPIRATION:
         iconSrc = NotificationTypeLogos.FANTASY_PLAYER_BOOSTER_EXPIRATION;
         break;
      case NOTIFICATION_TYPES.FANTASY_PLAYER_BOOSTER_PURCHASING:
         iconSrc = NotificationTypeLogos.FANTASY_PLAYER_BOOSTER_PURCHASING;
         break;
      case NOTIFICATION_TYPES.ABOUT_TO_EXPIRE_FANTASY_PLAYER_BOOSTER:
         iconSrc = NotificationTypeLogos.ABOUT_TO_EXPIRE_FANTASY_PLAYER_BOOSTER;
         break;
      case NOTIFICATION_TYPES.LEADERBOARD_RANK_REWARD_MATCH:
         iconSrc = NotificationTypeLogos.LEADERBOARD_RANK_REWARD;
         break;
      default:
         break;
   }
   return iconSrc ? iconSrc : "";
}

export function getTimeAgoString(dateTimeString) {
   const backendTimeMillis = Date.parse(dateTimeString);
   const currentTimeMillis = Date.now();
   const timeDifferenceSeconds = Math.floor((currentTimeMillis - backendTimeMillis) / 1000);

   if (timeDifferenceSeconds < 60) {
      return 'Just now';
   } else if (timeDifferenceSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceSeconds / 60);
      return `${minutes} mins ago`;
   } else if (timeDifferenceSeconds < 86400) {
      const hours = Math.floor(timeDifferenceSeconds / 3600);
      if (hours == 1) {
         return `${hours} hour ago`;
      } else {
         return `${hours} hours ago`;
      }
   } else {
      const days = Math.floor(timeDifferenceSeconds / 86400);
      if (days == 1) {
         return `${days} day ago`;
      } else {
         return `${days} days ago`;
      }
   }
}

export function getMinAndMaxYearForDob() {
   const currenTime = new Date();
   const currentYear = currenTime.getFullYear();
   const currentMonth = currenTime.getMonth() + 1;
   const minYear = currentYear - USER_PROFILE_CONSTANT.MAX_USER_AGE;
   const maxYear = currentYear - USER_PROFILE_CONSTANT.MIN_USER_AGE;
   const minDate = new Date(`${minYear}-${currentMonth}-01`);
   const maxDate = new Date(`${maxYear}-${currentMonth}-01`);
   return {minYear, maxYear};
}

export function birthDayValidator(day, month, year) {

   const minMax = getMinAndMaxYearForDob();

   function leapYear(inputYear) {
      return ((inputYear % 4 == 0) && (inputYear % 100 != 0)) || (inputYear % 400 == 0);
   }

   var m = parseInt(month);
   var d = parseInt(day);
   var y = parseInt(year);

   if (m === 0 || d === 0 || y === 0) {
      return false;
   } else if (day.length === 0 && month.length === 0 && year.length === 0) {
      return true;
   } else if (day === '' || month === '' || year === '') {
      return false;
   } else if (day.length > 2 && day.length < 1 && month.length > 2 && month.length < 1 && year.length === 4) {
      return false;
   } else if (y > minMax.minYear && y <= minMax.maxYear) {
      if (m > 12 || d > 31) {
         return false;
      } else if (m == 2 && d > 28) {
         if (d == 29) {
            if (!leapYear(y)) {
               return false;
            }
         } else {
            return false;
         }
      } else if (d > 30 && (m == 4 || m == 6 || m == 9 || m == 11)) {
         return false;
      } else if (m == 0 || d == 0 || y == 0) {
         return false;
      } else {
         return true;
      }
   } else {
      return false
   }
}

export function bytesToMegaBytes(bytes) {
   return bytes / (1024 * 1024);
}

//fast execution for array check for id
export function isExistChecker(array, id) {

   const idStore = {};

   array?.forEach((element) => {
      idStore[element?.id] = element;
   })

   id = parseInt(id);

   return !!idStore[id];

}

// export function useAuthTokenValidator(authToken, userName, authRefreshToken, refreshTokenFunction, logOutFunction) {
//   const dispatch = useDispatch();
//   const validateAuthAccess = validateToken(authToken);
//   validateAuthAccess.then((response) => {
//     if (response.data.apiStatus === false) {
//       const validateAuthRefresh = validateToken(authRefreshToken);
//       validateAuthRefresh.then((refreshResponse) => {
//         if (refreshResponse.data.apiStatus === true) {
//           if (userName !== null) {
//             const getRefreshedTokens = refreshToken(userName, authRefreshToken);
//             getRefreshedTokens.then((tokenResponse) => {
//               refreshTokenFunction(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
//             });
//           }
//         } else {
//           dispatch(actions.logout())
//           logOutFunction();
//         }
//       });
//     }
//   });
// }

export function authTokenValidator(authToken, userName, authRefreshToken, refreshTokenFunction, logOutFunction) {
   if (decryptData(localStorage.getItem('accessToken')) === false || decryptData(localStorage.getItem("refreshToken")) === false) {
      logOutFunction();
   }
   validateToken(authToken).then((response) => {
      if (response.data.apiStatus === false && userName != null) {
         const validateAuthRefresh = validateToken(authRefreshToken);
         validateAuthRefresh.then((refreshResponse) => {
            if (refreshResponse.data.apiStatus === true) {
               if (userName !== null) {
                  const getRefreshedTokens = refreshToken(userName, authRefreshToken);
                  getRefreshedTokens.then((tokenResponse) => {
                     refreshTokenFunction(tokenResponse.data.accessToken, tokenResponse.data.refreshToken);
                  });
               }
            } else {
               logOutFunction();
            }
         });
      } else if (userName == null || userName == undefined) {
         logOutFunction();
      }
   });
}

export function authRedirectHandler(navigate) {
   const redirectPath = sessionStorage.getItem('redirectPath');
   if (redirectPath === undefined || redirectPath === null) {
      return navigate("/")
   } else {
      return navigate(redirectPath)
   }
}

export function calculateTimeLeft(timeStamp) {
   if (timeStamp) {
      const targetTime = new Date(timeStamp);
      const currentTime = new Date().toLocaleString("en-US");
      const timeDiff = targetTime - new Date(currentTime);

      // Convert the time difference to days, hours, minutes, and seconds
      const secondsInMilliSeconds = 1000;
      const minutesInMilliSeconds = secondsInMilliSeconds * 60;
      const hoursInMilliSeconds = minutesInMilliSeconds * 60;
      const days = Math.floor(timeDiff / (hoursInMilliSeconds * 24));
      const hours = Math.floor((timeDiff % (hoursInMilliSeconds * 24)) / hoursInMilliSeconds);
      const minutes = Math.floor((timeDiff % hoursInMilliSeconds) / minutesInMilliSeconds);
      const seconds = Math.floor((timeDiff % minutesInMilliSeconds) / secondsInMilliSeconds);

      return {days, hours, minutes, seconds};
   } else {
      return {days: 0, hours: 0, minutes: 0, seconds: 0};
   }
}

export function formatNumberToK(value, numOfDecimalPlaces = 0) {
   if (value !== 0 && !value)
      return '--';
   if (value >= 1000) {
      return `${(value / 1000).toFixed(numOfDecimalPlaces)}K`;
   }
   return `${(value / 1).toFixed(numOfDecimalPlaces)}`;
}

export const formatNumberToKWithoutRounding = (points, numberOfDecimals = 2) => {
   if (!points) return 0;
   if (isNaN(points)) return 0;

   let val = points;
   let suffix = '';

   if (points >= 1000 || points <= -1000) {
      val = points / 1000;
      suffix = 'K';
   }

   let [intPart, decPart] = val.toString().split('.');

   if (numberOfDecimals > 0 && decPart) {
      decPart = decPart.substring(0, numberOfDecimals);
      return `${intPart}.${decPart}${suffix}`;
   } else {
      return `${intPart}${suffix}`
   }
}

export const decimalsWithoutRounding = (value, numberOfDecimals = 2) => {
   if (!value) return 0;
   if (isNaN(value)) return 0;

   let [intPart, decPart] = value.toString().split('.');

   if (numberOfDecimals > 0 && decPart) {
      decPart = decPart.substring(0, numberOfDecimals);
      return `${intPart}.${decPart}`;
   } else {
      return `${intPart}`
   }
}

export const pubnubMatchesTokenValidator = async () => {
   try {
      const response = await PubNubService.tokenValidation(localStorage.getItem("pubnubToken"),
         localStorage.getItem("uuid")
      ).then(response => {
         if (response.apiStatus != true) {
            localStorage.removeItem("pubnubToken")
         }

      });

   } catch (error) {
      console.error("Error:", error);
   }
}
export const pubnubLiveTokenValidator = async (userName) => {
   try {
      const response = await PubNubService.tokenValidation(localStorage.getItem("pubnubLiveToken"), userName)
         .then(response => {
            if (response.apiStatus != true) {

               localStorage.removeItem("pubnubLiveToken")
            }

         });

   } catch (error) {
      console.error("Error:", error);
   }
}

export function capitalize1stLetter(str) {
   return str ? str?.replace(/^./, str[0].toUpperCase()) : '';
}

export default function useDebounce(value, delay) {
   const [debouncedValue, setDebouncedValue] = useState(value);

   useEffect(() => {
      const timeoutId = setTimeout(() => {
         setDebouncedValue(value);
      }, delay);

      return () => clearTimeout(timeoutId);
   }, [value]);

   return debouncedValue;
}

export function toolTipProvider(toolTip, placement, component, tipCondition) {
   return (tipCondition ? <OverlayTrigger placement={placement} overlay={<Tooltip id="tooltip">{toolTip}</Tooltip>}>
      {component}
   </OverlayTrigger> : component);
}

export function handleCopy(event, copyUrl, translation) {
   event.preventDefault();

   // Create a text area element to hold the value to copy
   const textArea = document.createElement('textarea');
   // console.log("https://meta11.com/match/"+props.matchId);
   textArea.value = copyUrl

   // Append the text area to the document
   document.body.appendChild(textArea);

   // Select the text in the text area
   textArea.select();

   // Copy the selected text to the clipboard
   document.execCommand('copy');

   // Remove the temporary text area
   document.body.removeChild(textArea);

   toast.success(`${translation}`);
};

export function getPositionWithText(position) {
   if (isNaN(position) || position === 0) {
      return -1;
   }
   const lastDigit = position % 10;
   let suffix = 'th';
   switch (lastDigit) {
      case 1:
         suffix = 'st';
         break;
      case 2:
         suffix = 'nd';
         break;
      case 3:
         suffix = 'rd';
         break;
   }
   return `${position}${suffix}`;
}

export function playerRoleStyleController(role, pageLang) {

   if (pageLang == SITE_LANGUAGE.ENGLISH) {
      if (BowlingStylesEN.hasOwnProperty(role)) {
         return BowlingStylesEN[role];
      } else if (BattingStylesEN.hasOwnProperty(role)) {
         return BattingStylesEN[role];
      } else if (PlayingRolesEN.hasOwnProperty(role)) {
         return PlayingRolesEN[role];
      } else {
         return "N/A"
      }
   } else if (pageLang == SITE_LANGUAGE.HINDI) {
      if (BowlingStylesHI.hasOwnProperty(role)) {
         return BowlingStylesHI[role];
      } else if (BattingStylesHI.hasOwnProperty(role)) {
         return BattingStylesHI[role];
      } else if (PlayingRolesHI.hasOwnProperty(role)) {
         return PlayingRolesHI[role];
      } else {
         return "N/A"
      }
   }
}

export function handleDeviceScreenSize(windowInnterWidth) {
   if (windowInnterWidth <= 576) {
      return SCREEN_SIZES.MOBILESM;
   } else if (windowInnterWidth > 576 && windowInnterWidth <= 768) {
      return SCREEN_SIZES.MOBILELG;
   } else if (windowInnterWidth > 768 && windowInnterWidth <= 992) {
      return SCREEN_SIZES.TABSM;
   } else if (windowInnterWidth > 992 && windowInnterWidth <= 1240) {
      return SCREEN_SIZES.TABLG;
   } else {
      return SCREEN_SIZES.DESKTOP;
   }
}

export function getPlayerHittingRank(percentage, pageLang = SITE_LANGUAGE.ENGLISH) {
   let RANKS = PlayerHittingRanks;

   if (pageLang === SITE_LANGUAGE.HINDI) {
      RANKS = PlayerHittingRanksHI;
   }

   if (percentage >= 80 && percentage <= 100) {
      return RANKS.LEGEND;
   } else if (percentage >= 60 && percentage < 80) {
      return RANKS.CHAMPION;
   } else if (percentage >= 40 && percentage < 60) {
      return RANKS.SKILLED;
   } else if (percentage >= 20 && percentage < 40) {
      return RANKS.NOVICE;
   } else if (percentage >= 0 && percentage < 20) {
      return RANKS.ROOKIE;
   } else {
      return 'Invalid Rank';
   }
}

export const getPlayerShowingName = (player) => {
   // remove whitespace from both ends of the name and replace one or more whitespace characters with a single space
   const firstName = player?.firstName?.trim().replace(/\s+/g, " ");
   const lastName = player?.lastName?.trim().replace(/\s+/g, " ");

   let showingName = '';

   if (firstName && lastName) {
      showingName = firstName + ' ' + lastName;
   } else if (firstName) {
      showingName = firstName;
   } else if (lastName) {
      showingName = lastName;
   } else {
      showingName = player?.userName?.split('@')[0];
   }

   // return only first 2 names
   return showingName.split(' ').slice(0, 2).join(' ');
}

export const getShowingFantasyPoints = (number) => {
   const numberString = number.toString();
   const [intPart, decPart] = numberString.split('.');

   return {
      sign: parseInt(intPart) > 0 ? '+' : (parseInt(intPart) === 0 ? '' : '-'),
      intPart: parseInt(intPart) >= 0 ? intPart : intPart.substring(1),
      decPart: decPart ? '.' + decPart[0] : '.0'
   }
}

export const getRandomPlaceHolderImage = () => {
   const imagesList = [PlaceHolder1, PlaceHolder2, PlaceHolder3, PlaceHolder4];
   const index = Math.floor(Math.random() * imagesList.length);

   return imagesList[index];
}

export const getFantasyPointsStatusClass = (pointSign) => {
   if (pointSign === '+') {
      return "PositivePill";
   } else if (pointSign === '-') {
      return "NagativePill";
   } else {
      return "ZeroPill";
   }
}

export const getUsdValueFromPoints = (points, xpToSprtRatio, sprtToUsd) => {
   if (!points || !xpToSprtRatio || !sprtToUsd) return 0;
   if (points < 0 ) {
      points = 0
   }
   const usdValue = (points / xpToSprtRatio) * sprtToUsd;
   return usdValue.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]
}

export const getActualUsdValueFromPoints = (points, xpToSprtRatio, sprtToUsd) => {
   if (!points || !xpToSprtRatio || !sprtToUsd) return 0;
   const usdValue = (points / xpToSprtRatio) * sprtToUsd;
   return usdValue.toString().match(/^-?\d+(?:\.\d{0,5})?/)[0]
}

export const getSprtsValueFromPoints = (points, xpToSprtRatio) => {
   if (!points || !xpToSprtRatio) return 0;
   if (points < 0 ) {
      return 0;
   }
   const sprtsValue = points / xpToSprtRatio;
   return sprtsValue.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
}

export const getTimeDisplayText = (time) => {
   if (!time) return '--';
   if (time < 60) {
      return `${time} ${time > 1 ? 'mins' : 'min'}`;
   } else {
      const hr = Math.floor(time / 60);
      const min = time % 60;

      if (min === 0) {
         return `${hr} ${hr > 1 ? 'hours' : 'hour'}`;
      } else {
         return `${hr} ${hr > 1 ? 'hours' : 'hour'} ${min} ${min > 1 ? 'mins' : 'min'}`;
      }
   }
};

export const getConvertedTime = (time) => {
   if (typeof time === "number") {
      time = new Date(time * 1000).toISOString();
   }
   return time;
};

export const getBoosterRemainingTime = (activeBooster) => {
   const endTime = new Date(activeBooster?.endTime) - BOOSTER_EXTRA_TIME;
   const startTime = new Date(activeBooster?.startTime);
   const currentTime = new Date();

   if (new Date(startTime) > currentTime) {
      return ("Booster will be activated after starting the tournament");
   } else if (new Date(endTime) > currentTime) {
      const timeLeft = calculateTimeLeft(endTime);

      if (timeLeft.days > 0) {
         return (`Booster remains for ${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`);
      } else if (timeLeft.hours > 0) {
         return (`Booster remains for ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`);
      } else if (timeLeft.minutes > 0) {
         return (`Booster remains for ${timeLeft.minutes}m ${timeLeft.seconds}s`);
      } else if (timeLeft.seconds > 0) {
         return (`Booster remains for ${timeLeft.seconds}s`);
      } else {
         return ("No active booster");
      }
   } else {
      return "";
   }
}

export const getBoosterRemainingCircle = (activeBooster) => {
   if (activeBooster) {
      const endTime = new Date(activeBooster?.endTime) - BOOSTER_EXTRA_TIME;
      const startTime = new Date(activeBooster?.startTime);
      const currentTime = new Date();

      if (new Date(startTime) > currentTime) {
         return 0;
      }

      const boostedFor = new Date(endTime) - startTime;
      const remainingTime = new Date(endTime) - new Date();

      return 540 - (remainingTime / boostedFor) * 540;
   } else {
      return 540
   }
}

export const getBoosterFullTime = (booster) => {
   const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
   const startTime = new Date(booster?.startTime);
   const diff = new Date(endTime) - startTime;

   const hours = Math.floor(diff / 3600000);
   const minutes = Math.floor((diff % 3600000) / 60000);

   if (hours > 0 && minutes > 0) {
      return `${hours}h ${minutes}m`;
   } else if (hours > 0) {
      return `${hours}h`;
   } else {
      return `${minutes}m`;
   }
}

export const getBoosterFullTimeInMinutes = (booster) => {
   const endTime = new Date(booster?.endTime) - BOOSTER_EXTRA_TIME;
   const startTime = new Date(booster?.startTime);
   const diff = new Date(endTime) - startTime;
   return Math.floor(diff / 60000);
}
