import classNames from "classnames";
import React, {useEffect, useState} from "react";
import styles from "./FantasyTeams.module.scss";
import * as TournamentService from "../../services/TournamentService";
import NavbarSecondary from "../../components/NavigationBar/NavigationBar"
import Footer from "../../components/Footer/Footer";
import {connect} from "react-redux";
import TournamentFantasySection from "../../components/Tournament/FantasyTeamSection/TournamentFantasySection";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";

const FantasyTeams = (props) => {
   const navigate = useNavigate();
   const pageLang = localStorage.getItem('language') || 'en';

   const [loading, setLoading] = useState(false);
   const [selectedTournamentId, setSelectedTournamentId] = useState(-1);
   const [selectedTournamentDetails, setSelectedTournamentDetails] = useState(null);
   const [tournamentCards, setTournamentCards] = useState([]);
   const [translationsLoaded, setTranslationsLoaded] = useState(false);

   useEffect(() => {
      if (!props.isAuthenticated || !props.userName) {
         sessionStorage.setItem('redirectPath', window.location.pathname)
         navigate('/login', {replace: true});
      }
   }, [props.isAuthenticated, props.userName]);

   useEffect(() => {
      setTimeout(() => {
         setTranslationsLoaded(true);
      }, 2000);
   }, []);

   useEffect(() => {
      if (selectedTournamentId === -1) {
         setLoading(true);
         TournamentService.getAllTournaments("", 0, 4, "", "all", pageLang, props?.token)
            .then((res) => {
               if (res?.apiStatus && res?.tournaments) {
                  setTournamentCards(res?.tournaments);
                  setSelectedTournamentId(res?.tournaments[0]?.id);
               }
            })
            .finally(() => {
               setLoading(false);
            });
      } else {
         const selectedTournament = tournamentCards.find(tournament => tournament.id === selectedTournamentId);
         setSelectedTournamentDetails(selectedTournament);
      }
   }, [selectedTournamentId, props?.token, pageLang, tournamentCards]);

   const changeTournamentId = (value) => {
      setSelectedTournamentId(value.id);
   }

   if (!translationsLoaded) {
      return;
   }

   const loadingIndicator = <div className="text-center">
      <div style={{
         width: '500px', height: '500px', paddingTop: "150px", paddingLeft: "350px"
      }}>
         <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
         </div>
      </div>
   </div>;

   const getFantasyTeam = loading ? loadingIndicator : tournamentCards.length === 0 ?
      <div>{t("FANTASY.NO_TOURNAMENTS_AVAILABLE")}</div> : selectedTournamentDetails &&
      <TournamentFantasySection tournamentDetails={selectedTournamentDetails}/>;

   return (<div className={classNames(styles.bodyLeaderBoardOld, "ps-2 pe-2 ps-xxl-0 pe-xxl-0")}>
      <div className={classNames(styles.pageWrapper)}>
         <div
            className={classNames(styles.ResponceCont, "col-12", "row", "pb-2", "ps-2", "pe-2", "ps-xl-0", "pe-xl-0")}>
            <div className="col-12 p-0 row">
               <NavbarSecondary page="events"/>
            </div>

            <div className="container p-0">
               <div className="row gx-0">
                  <div className="col-md-12 col-xl-12">
                     <div className="row gx-0 mt-4">
                        <div className="col-12 ">
                           <h1 className={classNames(styles.sectionTitle, "mt-4 mb-4 w-100")}>
                              {t("FANTASY.YOUR_FANTASY_TEAMS")} </h1>
                        </div>
                     </div>
                     <div className="row">
                              <div className="col-12 p-0 mt-3">
                                 {tournamentCards[0]?.id && <>
                                    {tournamentCards.map(tournament => (<button
                                       style={{border: (tournament.id === selectedTournamentId) ? '1px solid #FAFF00' : undefined}}
                                       key={tournament.id}
                                       className={classNames("blackBtn roundBtn me-3 mb-2 mt-2 mt-sm-0 capitalize ", selectedTournamentId === tournament.id ? "selectedBtn" : "")}
                                       onClick={() => {
                                          changeTournamentId(tournament)
                                       }}
                                    >
                                       {tournament?.name}
                                    </button>))}
                                 </>}
                              </div>
                     </div>


                     <div className={classNames(styles.leaderboardRow, "row gx-4")}>
                        {getFantasyTeam}
                     </div>
                  </div>

               </div>
            </div>
         </div>

         <Footer/>
      </div>
   </div>);
}

const mapStateToProps = (state) => {
   return {
      isAuthenticated: state.auth.accessToken !== null && state.auth.accessToken !== undefined,
      profileImage: state.auth.imageURL,
      userName: state.auth.userName,
      token: state.auth.accessToken,
   };
};

export default connect(mapStateToProps)(FantasyTeams);
