export const Methods = {
   GET: "GET",
   POST: "POST",
   PUT: "PUT",
   DELETE: "DELETE",
   PATCH: "PATCH",
};

export const ApiEndpoints = {
   BASE_URL: "/api",

   //User APIs
   GET_USER_BY_USER_NAME: "/user",
   CREATE_NEW_USER_BY_EMAIL: "/user",
   UPDATE_USER: "/user/",
   LOGIN: "/user/auth/login",
   LOGIN_USER: "/auth/user/login",
   SEND_VERIFICATION_EMAIL: "/user/verification",
   SEND_RESET_EMAIL: "/user/forgotPassword",
   RESET_PASSWORD: "/user/resetPassword",
   RESET_PASSWORD_CODE: "/user/reset-password/code",
   SET_FAVOURITE_PLAYER: "/favourite/player",
   SET_FAVOURITE_TEAM: "/favourite/team",
   GET_FAVOURITES: "/favourite",
   GET_FOLLOWERS_FOLLOWING: "/user/follow",
   CHECK_EMAIL: "/user/checkEmail",
   CHECK_USERNAME: "/user/checkUsername",
   GET_ALL_USERS: "/user",
   GET_LEADERBOARD_MAX_AVG_RIGHT_ANSWERED_USER: "/leaderboard/average/highest",
   GET_LEADERBOARD_USER: "/leaderboard/main",
   GET_LEADERBOARD_USER_TOURNAMENT: "/leaderboard/user",
   GET_LEADERBOARD_MAIN_POINTS: "/leaderboard/main/points",
   UPDATE_USER_PRIVACY: "/user/private",
   GET_ARTWORK_TYPES: "/public/design-types",
   GET_PAST_MATCHES_BY_USER_NAME: "/resolver/result/history/matches",
   GET_USER_QUESTION_HISTORY: "/resolver/result/history",

   //Auth APIs
   SOCIAL_LOGIN: "/auth/user/socialLogin",
   REFRESH_SESSION: "/auth/api/refresh",
   TOKEN_VALIDATE: "/auth/user/auth/token",

   //File APIs
   UPLOAD_DESIGN_BLOB: "/file/upload",
   DELETE_DESIGN_BLOB: "/file/delete",
   DELETE_FILE_WITHOUT_TOKEN :"/file/in/delete",

   //Digital Asset APIs
   ASSET_UPLOAD: "/digitalAsset/createStaticAsset",
   PLAYER_ASSET_PROFILE: "/digitalAssets/playerProfile",
   GET_TEAM_PROFILE: "/digitalAssets/teamProfile",
   GET_TEAM_PROFILE_CARDS: "/digitalAssets/team/card",
   GET_TEAM_BY_TEAMID: "/digitalAssets/team",
   GET_TEAM_NEWS_BY_TEAMID: "/digitalAssets/team/news",
   GET_PLAYER_NEWS_BY_PLAYER_ID: "/digitalAssets/player/news",
   GET_TEAM_KIT_SPONSORS_BY_TEAMID: "/digitalAssets/team/kit-sponsor",
   GET_ALL_PLAYERS_CARDS: "/digitalAssets/player/card",
   GET_FAVOURITE_PLAYER_CARDS_BY_USER_ID: "/digitalAssets/player/card/favourite",
   GET_FAVOURITE_TEAM_CARDS_BY_USER_ID: "/digitalAssets/team/card/favourite",
   GET_PLAYER_PROFILE_BY_ID: "/digitalAssets/player",

   //Statistics Service APIs
   LIVE_MATCH_STAT: "/statistics/matches",
   LIVE_MATCHES_STATUS: "/statistics/matches/live",
   MATCH_STAT_CARDS: "/statistics/matches/card",
   MATCH_STAT_CARDS_BY_TEAMID: "/statistics/matches/card",
   HOME_MATCH_STAT: "/statistics/matches/home",
   PLAYER_PROFILE: "/statistics/players",
   GET_ALL_PROFILES: "/profiles",
   GET_ALL_TEAMS: "/statistics/teams",
   GET_ALL_TEAM_CARDS: "/statistics/teams/card",
   GET_TEAM: "/statistics/teams",
   GET_PLAYERS: "/statistics/players",
   GET_BATTING_RANKINGS: "/statistics/batting/rank",
   GET_BOWLING_RANKINGS: "/statistics/bowling/rank",
   GET_LAST_OUTCOME_OF_MATCHES_BY_TEAM_ID: "/statistics/last/outcome",
   GET_TEAM_MATCHES_HISTORY_BY_ID: "/statistics/teams/last5MatchStatusOfTournamentTeam",

   //Questionnaire Service APIs
   GET_QUESTION_PAPER: "/questionnaire/paper",
   ANSWER_SUBMIT_BY_MATCH_ID: "/questionnaire/question/user",
   SUBMIT_ANSWERS: "/questionnaire/user/answer",
   GET_QUESTIONS_BY_MATCH_ID: "/questionnaire/question/match",

   //Interaction Service APIs
   GET_NOTIFICATIONS_BY_USERID: "/interaction/notification",
   UPDATE_MATCH_CHEER: "/interaction/cheer",

   //Application Statistics Service APIs
   CREATE_LIVE_USER: "/applicationStatistics/users/live",
   UPDATE_LIVE_USER: "/applicationStatistics/users/live",
   GET_LIVE_USERS_COUNT: "/applicationStatistics/users/live",

   //Community Service APIs
   GET_ARTWORK_BY_ID: "/community/artwork/",
   UPDATE_USER_ARTWORK_TOKEN: "/community/artwork/",
   GET_ARTWORK_BY_USER_ID_AND_TYPE: "/community",
   GET_ALL_ARTWORKS: "/community",

   //Schedule Service APIs
   GET_SCHEDULED_MATCH: "/scheduler/matches",

   //Resolver Service APIs
   GET_LEADER_BOARD_HISTORY_BY_MATCH_ID_AND_USER_ID: "/resolver/match/user",
   GET_LEADER_BOARD_HISTORY: "/resolver/match/user/leaderboard",
   GET_LEADER_BOARD_HISTORY_BY_USER_ID: "/resolver/match/user",
   GET_PREDICTION_HISTORY: "/resolver/user/history/matchPrediction",
   GET_PREDICTION_RESULT: "/resolver/user/history/overPredictionSummary",

   //Pubnub Service APIs
   GET_ALL_TOKEN: "/message/token/all",
   GET_LIVE_TOKEN: "/message/token/live",
   GET_MATCH_TOKEN: "/message/token/match",
   GET_MATCHES_TOKEN: "/message/token/matches",
   SEND_ANSWERS: "/simulation/user/answer",
   CHECK_MATCHES_TOKEN: "/message/token/check",

   //Tournament Service APIs
   GET_LATEST_TOURNAMENT: "/tournament/latest",
   GET_TOURNAMENT: "/tournament",
   GET_KNOCKOUT_TOURNAMENT_BY_ID: "/tournament/knockout",
   GET_TOURNAMENT_TEAMS_BY_ID: "/tournament/teams",
   GET_TOURNAMENT_REGISTRATION: "/tournament/register",
   GET_USER_REWARD_CATEGORY: "/reward/getUserRewardCategory",

   //Wallet Service APIs
   GET_USER_CREDITS: "/wallet/omniCredit",
   GET_OC_PURCHASE_PACKAGES: "/wallet/omniCredit/packages",
   GET_OC_EXCHANGE_RATE: "/wallet/omniCredit/getLatestRates",
   SEND_WALLET_VERIFICATION_EMAIL: "/wallet/verification",
   VERIFY_WITHDRAW_CODE: "/wallet/verifyWithdrawRequest",
   GET_USER_TRANSACTIONS: "/wallet/getUserTransactions",
   CHECK_WITHDRAW_ELIGIBILITY: "/wallet/eligibleToWithdraw",
   PURCHASE_FANTASY_PLAYER_BOOST: "/wallet/purchase/fantasy/boost",

   //LeaderBoard Service APIs
   GET_ACTIVE_TOURNAMENT_LIST: "/tournament/active/list",
   GET_TOURNAMENT_USER_LEADERBOARD: "/leaderboard",
   GET_USER_LEADERBOARD: "/leaderboard/main/getRecords",
   GET_POSITION_CHART_DETAILS : "leaderboard/user/chart",
   GET_REWARD_CONFIG: "/leaderboard/reward/configs",

   //Cart Service APIs
   CREATE_NEW_CART: "/cart",
   CHECKOUT_CART: "/cart/",

   //Blogs Service APIs
   GET_ALL_TESTIMONIALS: "/testimonial",

   // Fantasy Service APIs
   GET_USER_FANTASY_SUBSCRIPTION: "/fantasy/user",
   GET_PLAYERS_BY_TOURNAMENT_ID: "/fantasy/tournament",
   GET_FANTASY_TEAM_BY_USERNAME_AND_TOURNAMENT_ID: "/fantasy/tournament",
   POST_USER_FANTASY_TEAM: "/fantasy/user",
   GET_PLAYER_FANTASY_MATCH_HISTORY: "/fantasy/player",
   GET_FANTASY_RULES: "/fantasy/rules",
   GET_USER_FANTASY_TOURNAMENT_HISTORY: "/fantasy/user/history",
   GET_BOOSTER_RULES_POINTS: "/fantasy/boosters",
   GET_PLAYER_BOOST_HISTORY: "/fantasy/player/boosts",
};
